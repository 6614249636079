import restClient from "./api";

class MetricsService 
{

  getMetric(metricName) 
  {
    return restClient.post('/metrics-service/query-by-name',{name: metricName});
  }

  getRemaining2D3DCases()
  {
    return this.getMetric("REMAINING_CASES_2D3D");
  }
}

export default new MetricsService();